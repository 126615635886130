import { useState } from 'react';
import {
	FormHelperText,
	Typography,
	TextField,
	Button,
	Checkbox,
	FormControlLabel,
	Box,
	CircularProgress,
} from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleLogin } from '@react-oauth/google';
import axios from '../axiosConfig';

const LoginPage = ({ onGoogleLoginSuccess, onCustomLoginSuccess }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);

	const [recaptchaToken, setRecaptchaToken] = useState(null); // Store reCAPTCHA token
	const [captchaError, setCaptchaError] = useState(false);
	const [serverError, setServerError] = useState('');

	// Google OAuth setup
	const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

	const handleSubmit = async (e) => {
		e.preventDefault();
		setEmailError(false);
		setPasswordError(false);
		setCaptchaError(false);
		setServerError('');

		if (!email.includes('@')) {
			setEmailError(true);
			return;
		}

		if (password.length === 0) {
			setPasswordError(true);
			return;
		}

		if (!recaptchaToken) {
			setCaptchaError(true);
			return;
		}

		try {
			setLoading(true);
			const response = await axios.post('/auth/login', { email, password });
			setLoading(false);

			if (response.data.message === 'Login successful') {
				onCustomLoginSuccess({ email, role: response.data.role });
			} else {
				setServerError('Unexpected response from server.');
			}
		} catch (error) {
			setLoading(false);
			if (error.response && error.response.data.error) {
				setServerError(error.response.data.error);
			} else {
				setServerError('An error occurred during login.');
			}
		}
	};

	// Trigger Google login
	// const handleGoogleLogin = () => {
	//   google.accounts.id.prompt(); // Opens the Google login popup
	// };

	const handleRecaptchaChange = (token) => {
		setRecaptchaToken(token);
		if (token) setCaptchaError(false);
	};

	return (
		<Box
			className='loginContainer'
			sx={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start', // Start closer to the top
				alignItems: 'center',
				backgroundColor: '#FAFAFA',
				paddingTop: '32px', // Adjust top padding to bring card closer to the top
			}}
		>
			<Box sx={{ maxWidth: '580px', width: '100%', textAlign: 'center' }}>
				{' '}
				{/* Remove Card */}
				{/* Welcome Text */}
				<Typography
					variant='h4'
					component='h2'
					align='left'
					mb={1}
					sx={{
						fontFamily: 'var(--fontFamily)',
						fontSize: '2.125rem',
						fontWeight: 400,
						color: '#000000DE',
						textAlign: 'left',
					}}
				>
					Welcome
				</Typography>
				{/* Form Fields */}
				<form onSubmit={handleSubmit} style={{ textAlign: 'left' }} noValidate>
					<TextField
						label='Email'
						variant='filled'
						size='medium'
						margin='normal'
						required
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
							setEmailError(false);
						}}
						error={emailError}
						disabled={loading}
						helperText={emailError && 'Enter a valid email address'}
						fullWidth
						sx={{ marginBottom: '16px' }}
					/>
					{emailError && <FormHelperText error>Enter a valid email address</FormHelperText>}
					<TextField
						label='Password'
						variant='filled'
						size='medium'
						margin='normal'
						required
						type={showPassword ? 'text' : 'password'}
						value={password}
						onChange={(e) => {
							setPassword(e.target.value);
							setPasswordError(false);
						}}
						error={passwordError}
						disabled={loading}
						fullWidth
						helperText={passwordError && 'Enter your password'}
						sx={{ marginBottom: '16px' }}
					/>
					{passwordError && <FormHelperText error>Enter a valid password</FormHelperText>}
					<FormControlLabel
						control={<Checkbox checked={showPassword} onChange={() => setShowPassword(!showPassword)} />}
						label='Show password'
						sx={{ marginBottom: '16px', textAlign: 'left' }}
					/>
					<ReCAPTCHA sitekey={siteKey} onChange={handleRecaptchaChange} />
					{captchaError && <FormHelperText error>Please complete the CAPTCHA</FormHelperText>}
					<Button
						type='submit'
						fullWidth
						variant='contained'
						disabled={loading}
						sx={{ marginTop: '16px', backgroundColor: '#673AB7' }}
					>
						{loading ? <CircularProgress size={24} color='inherit' /> : 'LOG IN'}
					</Button>

					{serverError && <FormHelperText error>{serverError}</FormHelperText>}

					<Typography sx={{ marginY: '16px', color: '#00000099', fontSize: '14px', textAlign: 'center' }}>
						OR
					</Typography>
					<div className='googleLoginBtn'>
						<GoogleLogin
							useOneTap
							theme='filled_blue'
							width='400'
							onSuccess={(credentialResponse) => {
								onGoogleLoginSuccess(credentialResponse.credential);
							}}
						/>
					</div>
				</form>
			</Box>

			{/* Footer Section */}
			<Box
				component='footer'
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					padding: '16px',
					backgroundColor: '#FAFAFA',
					width: '100%',
					position: 'fixed',
					bottom: 0,
					fontSize: '14px',
					color: 'rgba(0, 0, 0, 0.6)',
				}}
			>
				<Typography component='span' sx={{ mx: 1 }}>
					© 2024 The Growth Spark
				</Typography>
				<Typography component='span' sx={{ mx: 1 }}>
					Request Access
				</Typography>
				<Typography component='span' sx={{ mx: 1 }}>
					Lorem Ipsum
				</Typography>
				<Typography component='span' sx={{ mx: 1 }}>
					Dolor Si Amet
				</Typography>
			</Box>
		</Box>
	);
};

export default LoginPage;
